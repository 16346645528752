import { GrouppedDocsGeneratorService } from '../../../services/groupped-docs-generator.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { DocumentsSelectorComponent } from './documents-selector/documents-selector.component';
import { GrouppedProductsContainerComponent } from './groupped-products-container/groupped-products-container.component';
import { ProductsContainerPerDocumentsComponent } from './products-container-per-documents/products-container-per-documents.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TotalsMinimalContainerComponent } from './totals-minimal-container/totals-minimal-container.component';
import { GroupedViewDocsComponent } from './grouped-view-docs/grouped-view-docs.component';
import { ProviderHeaderInfoComponent } from "./provider-header-info/provider-header-info.component"
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { TotalsContainerComponent } from './totals-container/totals-container.component';
import { DocsFooterInfoComponent } from './docs-footer-info/docs-footer-info.component';
import { AssociateDocsComponent } from './associate-docs/associate-docs.component';
import { DocsFiltersComponent } from './docs-filters/docs-filters.component';
import { QuotePopUpComponent } from './quote-pop-up/quote-pop-up.component';
import { ViewInfosComponent } from './view-infos/view-infos.component';
import { ViewDocsComponent } from './view-docs/view-docs.component';
import { RebatesContainerComponent } from './rebates-container/rebates-container.component';
import { AddProductTagModule } from '../add-product-tag/add-product-tag.module';
import { InventoryFilterComponent } from './inventory-filter/inventory-filter.component';
import { AssociateDocsPurchasesComponent } from './associate-docs-purchases/associate-docs-purchases.component';
import { SimplifiedDocComponent } from './view-docs/simplified-doc/simplified-doc.component';
import { ProductColisageSelectorComponent } from './product-colisage-selector/product-colisage-selector.component';
import { ProductPriceUnitComponent } from './product-price-unit/product-price-unit.component';
import { StockProductContainerComponent } from './stock-product-container/stock-product-container.component';
import { ClientHeaderInfoComponent } from './client-header-info/client-header-info.component';
import { ProductsContainerComponent } from './products-container/products-container.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TotalsMinimalDSAContainerComponent } from './totals-minimal-dsa-container/totals-minimal-dsa-container.component';
import { TotalsDSAContainerComponent } from './totals-dsa-container/totals-dsa-container.component';
import { DamagesProductContainerComponent } from './damages-product-container/damages-product-container.component';
export const MY_FORMAT: MatDateFormats = {
    parse: {
        // tslint:disable-next-line: no-duplicate-string
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD-MM-YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@NgModule({
    declarations: [
        DocumentsSelectorComponent,
        GrouppedProductsContainerComponent,
        ProductsContainerPerDocumentsComponent,
        TotalsMinimalContainerComponent,
        GroupedViewDocsComponent,
        ProviderHeaderInfoComponent,
        ProductsContainerComponent,
        ClientHeaderInfoComponent,
        TotalsContainerComponent,
        DocsFooterInfoComponent,
        AssociateDocsComponent,
        DocsFiltersComponent,
        InventoryFilterComponent,
        ViewInfosComponent,
        QuotePopUpComponent,
        ViewDocsComponent,
        RebatesContainerComponent,
        AssociateDocsPurchasesComponent,
        SimplifiedDocComponent,
        ProductSelectorComponent,
        ProductColisageSelectorComponent,
        ProductPriceUnitComponent,
        StockProductContainerComponent,
        TotalsMinimalDSAContainerComponent,
        TotalsDSAContainerComponent,
        
        //StockHeaderInfoComponent
    ],
    imports: [

        CommonModule,
        TranslateModule,
        MaterialModule,
        FormsModule,
        FuseSharedModule,
        ReactiveFormsModule,
        MatSelectModule,
        DragDropModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        TextMaskModule,
        NgSelectModule,
        MatIconModule,
        AddProductTagModule,


    ],
    providers: [
        GrouppedDocsGeneratorService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
    exports: [
        DocumentsSelectorComponent,
        GrouppedProductsContainerComponent,
        ProductsContainerPerDocumentsComponent,
        TotalsMinimalContainerComponent,
        GroupedViewDocsComponent,
        ProviderHeaderInfoComponent,
        ProductsContainerComponent,
        TotalsContainerComponent,
        ClientHeaderInfoComponent,
        DocsFooterInfoComponent,
        AssociateDocsComponent,
        DocsFiltersComponent,
        QuotePopUpComponent,
        // StockHeaderInfoComponent,
        ViewInfosComponent,
        ViewDocsComponent,
        RebatesContainerComponent,
        InventoryFilterComponent,
        AssociateDocsPurchasesComponent,
        ProductSelectorComponent,
        ProductColisageSelectorComponent,
        ProductPriceUnitComponent,
        StockProductContainerComponent,
        TotalsDSAContainerComponent,
        TotalsMinimalDSAContainerComponent,
        
        
    ]
})
export class DocumentsModule { }
